
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    TextControl,
    IntegerControl,
    DecimalControl,
    CatalogueSelectControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
      this.model = model;
      this.firstDisplay = true;
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    onTypeChange() {
      if (this.firstDisplay) this.firstDisplay = false;
      else this.resetModel();
    },
    resetModel() {
      for (var key in this.model) {
        switch (key) {
          case "id":
          case "name":
          case "displacementId":
          case "displacementDescription":
            break;
          case "gapId":
          default:
            if (typeof this.model[key] == "string") this.model[key] = null;
            if (typeof this.model[key] == "number") this.model[key] = 0;
        }
      }
    },
    onShown(model: any) {
      (this as any).mountComponent(
        "components/catalogues/TransportCatalogue/TransportContainerRequisiteSubCatalogueGrid.vue",
        (this as any).requisiteSubGridDomId,
        {
          parentModel: model,
        }
      );
    },
    beforeUnmount() {
      (this as any).unmountComponent((this as any).requisiteSubGridDomId);
    },
  },
  data() {
    return {
      firstDisplay: true,
      model: null,
      requisiteSubGridDomId:
        "TransportContainerCreateEditDialog_requisiteSubGrid",
    };
  },
});
